import React, { Component } from 'react'
import { history } from '../../routers' 
import { Button, Modal, Col, Row } from 'antd'
import MyIcon from '../../components/myIcon'

export default class ScanModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }
  show() {
    this.setState({visible: true})
  }
  close = () => {
    this.setState({visible: false})
  }
  signOut() {
    localStorage.clear('token')
    localStorage.clear('refreshToken')
    localStorage.clear('TEI')
    localStorage.clear('ST')
    localStorage.clear('RTEI')
    this.setState({visible: false})
    history.push('/login')
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { visible } = this.state
    return(
      <Modal
        visible={visible}
        footer={null}
        width={360}
        onCancel={this.close}
        mask={false}
        closable={false}
        centered
      > 
        <MyIcon type='icon-wenti_msg' style={{display: 'block', margin: 'auto', fontSize: 50, marginTop: 50}} />
        <p style={{textAlign: 'center', fontSize: 16,  marginTop: 20}}>确认退出吗?</p>
        <Row type='flex' justify='center' style={{margin: '30px 0 20px 0'}}>
          <Col span={11}>
            <Button type='primary' style={{height: 36}} ghost onClick={this.close}>取消</Button>&nbsp;&nbsp;
            <Button type='primary' style={{height: 36}} onClick={() => {this.signOut()}}>确认</Button>
          </Col>
        </Row> 
      </Modal>
    )
  }
}