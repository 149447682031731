import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'ExpertSelection',
    params: {
      page: 1,
      pageSize: 10,
      status: 1
    },
    totalRows: 1,
    reviewedList: [],
    unreviewedList: [],
    reviewedDetail: {}
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    async getReviewedList(payload, rootState) {
      const url = '/api/score/page'
      const params = {
        ...rootState.ExpertSelection.params,
        ...payload
      }
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          reviewedList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    }
  })
}
