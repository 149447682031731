import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
export default {
  state: {
    name: 'EcgView',
    allData: {}
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    async getEcgData(payload){
        const url = `/api/study/getAllEcgCoordinates/${payload}`
        let res = await request.get(url)
        if(res.status === 0){
            this.updateState({
                allData: res.data
            })
        }
    }
  })
}