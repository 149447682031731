import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../../routers'
import { Layout, Menu } from 'antd'
// import MyIcon from '../../components/myIcon'
import './layouts.styl'

class SiderBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      permsKeys: [],
      defaultKey: '',
      subKeys: [],
      permsSiderList: [],
      siderList: [
        // {
        //   name: '系统设置',
        //   key: 'permsSystem',
        //   iconType: 'iconfont icon-xitongshezhi',
        //   sub: [
        //     { name: '权限管理', key: 'permission', iconType: '', routerPath: '/permission' },
        //     { name: '角色管理', key: 'role', iconType: '', routerPath: '/roleControll' },
        //     { name: '用户管理', key: 'user', iconType: '', routerPath: '/userControll' },
        //     { name: '医院管理', key: 'hospital', iconType: '', routerPath: '/hospitalControll' },
        //   ]
        // },
        // {
        //   name: '系统设置',
        //   key: 'ecgSystemControll',
        //   iconType: 'iconfont iconshezhi',
        //   show: true,
        //   sub: [
        //     { name: '新增用户', key: 'addEcgUser', iconType: '', routerPath: '/addEcgUser' },
        //     { name: '用户管理', key: 'ecgUserList', iconType: '', routerPath: '/ecgUserList' },
        //     // { name: '新增机构', key: 'addEcgHospital', iconType: '', routerPath: '/addEcgHospital', show: true },
        //     // { name: '机构管理', key: 'ecgHospitalList', iconType: '', routerPath: '/ecgHospitalList', show: true }
        //   ]
        // },
        {
          name: '检查管理',
          key: 'ecgRegisterControll',
          // iconType: 'iconfont iconjiancha',
          show: false,
          sub: [
            // { name: '新增图片', key: 'addRegister', iconType: '', routerPath: '/addRegister' },
            { name: '检查列表', key: 'registerList', iconType: '', routerPath: '/registerList' },
          ]
        },
      ]
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.filterSider(nextProps.state.permsKey)

    this.setLocationPermsKey()

  }
  componentDidMount() {
    this.filterSider(this.props.state.permsKey)

    this.setLocationPermsKey()

    if (window.isPad) {
      this.setState({
        collapsed: true
      })
    }
  }
  setLocationPermsKey = () => {
    const pathName = window.location.pathname
    localStorage.removeItem('defaultKey')
    const routerPath = pathName
    const siderList = this.state.siderList

    for (let i = 0; i < siderList.length; i++) {
      for (let j = 0; j < siderList[i].sub.length; j++) {
        if (routerPath.indexOf(siderList[i].sub[j].routerPath) > -1) {

          this.setState({
            defaultKey: siderList[i].sub[j].key,
            subKeys: [siderList[i].key]
          })
          localStorage.breadPosition = siderList[i].sub[j].name

          break
        }
      }
    }
  }
  subTitleClick = (e) => {
    if (window.isPad) {
      this.setState({
        collapsed: false
      })
    }
  }
  filterSider = (keys) => {
    const siderList = this.state.siderList
    let permsSiderList = JSON.parse(JSON.stringify(siderList))
    // 深拷贝，保证原有对象不变

    const len = siderList.length
    let permsKeys

    if (keys) {
      permsKeys = keys
    } else {
      permsKeys = localStorage.permsKeys ? localStorage.permsKeys.split(',') : []
    }

    for (let i = 0; i < len; i++) {
      if (siderList[i] && permsKeys.includes(siderList[i].key)) {
        for (let j = 0; j < siderList[i].sub.length; j++) {
          if (siderList[i].sub[j] && permsKeys.includes(siderList[i].sub[j].key)) {
            continue
          } else {
            delete permsSiderList[i].sub[j]
          }
        }
      } else {
        delete permsSiderList[i]
      }
    }

    this.setState({
      permsSiderList
    })
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }
  stest = (obj) => {

  }
  siderJump = (routerPath, val, name, subKey, tname) => {
    localStorage.removeItem('defaultKey')
    if (window.isPad) {
      this.setState({
        collapsed: true
      })
    }

    this.setState({
      defaultKey: val.key
    })
    localStorage.breadPosition = name
    // document.title = '睿眼'

    localStorage.indexPosition = tname
    this.props.setBreadPosition(name)
    this.props.setIndexPosition(tname)
    history.push(routerPath)
  }
  jumpIndex = () => {
    history.push('/')
    this.setState({ defaultKey: '', subKeys: [] })
  }
  menuOnOpenChange = (e) => {
    this.setState({
      subKeys: e
    })
  }
  render() {
    const { Sider } = Layout
    const { SubMenu } = Menu

    return (
      <Sider
        // style={{padding: '0 10px'}}
        collapsible={window.isPad ? true : false}
        theme='dark'
        width={180}
        style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, zIndex: 2 }}
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse} >
        {
          this.state.collapsed ?
            <div className="slogo">
              <div
                style={{ cursor: 'pointer' }}
                onClick={this.jumpIndex}
              >
              </div>
            </div> :
            <div className="logo">
              <div
                style={{ cursor: 'pointer' }}
              // onClick={this.jumpIndex}
              >
              </div>
            </div>
        }

        <Menu
          // defaultOpenKeys={this.state.subKeys}
          openKeys={this.state.subKeys}

          theme="dark"
          onClick={this.stest}
          onOpenChange={this.menuOnOpenChange}
          selectedKeys={[localStorage.defaultKey || this.state.defaultKey]}
          mode="inline">
          {
            this.state.siderList.map((v, i) => {
              return (
                <SubMenu
                  onTitleClick={this.subTitleClick}
                  style={{ display: `${(!v.show || this.props.personal.userInfo.permsGroupId != 2) ? 'block' : 'none'}` }}
                  key={v.key}
                  title={
                    <span style={{ fontSize: 14 }} className={v.iconType}>
                      {!this.state.collapsed ?
                        <span style={{ paddingLeft: 23, fontSize: 14 }}>{v.name}</span> : null} </span>}>
                  {
                    v.sub.map((sv, si) => {
                      return (
                        <Menu.Item
                          style={{ display: `${(!sv.show || this.props.personal.userInfo.permsGroupId == 1) ? 'block' : 'none'}` }}
                          // style={{ width: '160px', height:30, lineHeight:'30px', borderRadius: '6px', margin: '10px 10px'}}
                          key={sv.key}
                          onClick={(val) => { this.siderJump(sv.routerPath, val, sv.name, v.key, v.name) }}>
                          <span>{sv.name}</span>
                        </Menu.Item>
                      )
                    })
                  }
                </SubMenu>
              )
            })
          }
        </Menu>
      </Sider>
    )
  }
}

const mapState = state => {
  return {
    state: state.Layouts,
    personal: state.Personal
  }
}

const mapDispatch = ({ Layouts: { setBreadPosition, getPermsKeys, setIndexPosition } }) => {
  return {
    setBreadPosition: (pos) => setBreadPosition(pos),
    getPermsKeys: () => getPermsKeys(),
    setIndexPosition: (pos) => setIndexPosition(pos)
  }
}

export default withRouter(connect(mapState, mapDispatch)(SiderBar));