export default {
  state: {
    name: 'App',
    count: 2123
  }, // initial state
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload }
    },
    appAdd(state, payload) {
      const newVal = state.count + payload
      // return Object.assign({}, state, {count: newVal})
      return {
        ...state,
        ...{count: newVal}
      }
    }
  },
  effects: {
    async appAddAsync(payload) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.appAdd(payload)
    }
  }
}