import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'ReportDetail',
    infoData: {},
    authentication: ''
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    },
  },
  effects: () => ({
    async patientDetail(payload) {
      const url = `/api/patient/detail/${payload}`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({ infoData: res.data })
        localStorage.evaluations = JSON.stringify(res.data.evaluations)
        return res.data
      }
    },
  })
}