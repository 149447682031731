import React from 'react'
import { message } from 'antd'
import MyIcon from './myIcon'

export const modelMsg = (status, msg) => {
  let icon
  if (status === 0) {
    icon = <MyIcon type='icon-chenggong' />
  } else if (status === 1) {
    icon = <MyIcon type='icon-tishi' />
  } else if (status === 2) {
    icon = <MyIcon type='icon-collect' />
  } else if (status === 3) {
    icon = <MyIcon type='icon-cancel-collect' />
  } else {
    icon = <MyIcon type='icon-jinggao' />
  }
  const node = <span style={{ color: 'rgba(0,0,0,0.85)' }}> {msg} </ span>
  const node2 = <span style={{ color: '#67C23A' }}> {msg} </ span>
  const node3 = <span style={{ color: '#E7A23D' }}> {msg} </ span>
  message.open({ icon, content: msg == '收藏成功！' ? node2 : msg == '已取消收藏！' ? node3 : node })
}