import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import axios from 'axios'

export default {
  state: {
    name: 'Warning',
    totalRows: 0,
    historyTotal: 0,
    favoriteTotal: 0,
    statTotal: 0,
    statPage: 1,
    OrgList: [],
    eventTopList: [],
    warnList: [],
    eventCountList: [],
    trendList: [],
    distributionList: [],
    searchList: [],
    favoriteList: [],
    albumList: [],
    searchTypeList: [],
    statList: [],
    warnDiseaseList: [],
    modelSolarTermList: [],
    weatherInfo: {},
    symptomInfo: {},
    warnDiseaseTime: '',
    diseaseTotal: 0,
    syndromeTotal: 0,
    params: {
      page: 1,
      pageSize: 10
    },
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    async setUserAuth(params) {
      const url = `/api/user/auth?account=${params}`
      const res = await request.post(url)
      if (res.status === 0) {
        localStorage.token = res.data.token
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getStatPage(params) {
      const url = '/api/search/statPage'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // res.data.push({ key: 3, value: 8 })
        this.updateState({
          statList: res.data.pageData,
          statTotal: res.data.totalRows,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getKeywords(params) {
      const url = '/api/search/keywords'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        return res.data
      }
      modelMsg(res.status, res.msg)
    },
    async getSearchTrend(params) {
      const url = '/api/search/trend'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        return res.data
      }
      modelMsg(res.status, res.msg)
    },
    async getSearchType(params) {
      const url = '/api/search/distribution'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        return res.data
      }
      modelMsg(res.status, res.msg)
    },
    async getDistribution(params) {
      const url = '/api/warning/distribution'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // res.data.push({ key: 3, value: 8 })
        this.updateState({
          distributionList: res.data
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async cancelCollect(id) {
      const url = `/api/search/cancelCollect/${id}`
      const res = await request.post(url)
      if (res.status === 0) {
        return res.status
      }
      modelMsg(res.status, res.msg)
    },
    async addAlbum(params) {
      const url = '/api/search/addAlbum'
      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getAlbumList()
        modelMsg(res.status, '新建成功')
        return res.status
      }
      modelMsg(res.status, res.msg)
    },
    async removeAlbum(id) {
      const url = `/api/search/removeAlbum/${id}`
      const res = await request.post(url)
      if (res.status === 0) {
        this.getAlbumList()
        return modelMsg(0, '删除成功')
      }
      modelMsg(res.status, res.msg)
    },
    async getAlbumList(params) {
      const url = '/api/search/albumList'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          albumList: res.data
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getSearchFavorite(params) {
      const url = '/api/search/favoritePage'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          favoriteList: res.data.pageData,
          favoriteTotal: res.data.totalRows
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getSearchPage(params) {
      const url = '/api/search/page'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          searchList: res.data.pageData,
          historyTotal: res.data.totalRows
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async removeSearch(id) {
      const url = `/api/search/remove/${id}`
      const res = await request.post(url)
      if (res.status === 0) {
        // res.data.push({ key: 3, value: 8 })
        // this.updateState({
        //   searchList: [...res.data.pageData, { reference: true, collected: false }]
        // })
        modelMsg(0, '删除成功')
        return res.status
      }
      modelMsg(res.status, res.msg)
    },
    async getTrend(params) {
      const url = '/api/warning/trend'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          trendList: res.data
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getAvailableSolarTerms(params) {
      const url = '/api/warning/availableSolarTerms'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          modelSolarTermList: res.data
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getSymptomInfo(params) {
      const url = '/api/warning/symptomInfo'
      const res = await request.get(url, { params })
      let syndromeTotal = 0, diseaseTotal = 0
      if (res.status === 0) {
        if (res.data) {
          JSON.parse(res.data.top10disease).map(i => {
            diseaseTotal += i.diseaseNumber
          })
          JSON.parse(res.data.top10syndrome).map(i => {
            syndromeTotal += i.syndromeNumber
          })
          res.data.averageHumidity = res.data.averageHumidity.split('%')[0]
          const humLeft = ((res.data.averageHumidity * 0.009 - (res.data.averageHumidity && 0.025) - (res.data.averageHumidity == 100 && 0.03)))
          const uvLeft = ((res.data.averageUvIndex * 0.06 - (res.data.averageUvIndex && 0.025) - (res.data.averageUvIndex == 15 && 0.03))) //  0.06=0.9rem/15
          if (document.querySelector('.bigScreen-modal')) {
            document.querySelector('.bigScreen-modal .type-progress-rect').style.left = (JSON.parse(res.data.averageTem)[1] - JSON.parse(res.data.averageTem)[0]) * (0.9 / (JSON.parse(res.data.averageTem)[3] - JSON.parse(res.data.averageTem)[0])) + 'rem' // 0.053=0.9rem/17 (0.9是.progress的宽度，17是进度条最高值)
            document.querySelector('.bigScreen-modal .type-progress-rect').style.width = (JSON.parse(res.data.averageTem)[2] - JSON.parse(res.data.averageTem)[1]) * (0.9 / (JSON.parse(res.data.averageTem)[3] - JSON.parse(res.data.averageTem)[0])) + 'rem'
            document.querySelector('.bigScreen-modal .rate').style.left = humLeft + 'rem'
            // 0.009=0.9rem/100 
            document.querySelector('.bigScreen-modal .rate-rays').style.left = uvLeft + 'rem'
            document.querySelector('.bigScreen-modal .humidity-num').style.left = humLeft - (res.data.averageHumidity.length == 2 ? 0.05 : 0.02) + 'rem'
            document.querySelector('.bigScreen-modal .uv-num').style.left = uvLeft - (res.data.averageUvIndex.length == 2 ? 0.03 : 0) + 'rem'
          }
          if (document.querySelector('.rate') && document.querySelector('.type-progress-rect') && document.querySelector('.rate-rays') && document.querySelector('.humidity-num')) {
            document.querySelector('.rate').style.left = humLeft + 'rem'
            // 0.009=0.9rem/100 
            document.querySelector('.rate-rays').style.left = uvLeft + 'rem'
            document.querySelector('.type-progress-rect').style.left = (JSON.parse(res.data.averageTem)[1] - JSON.parse(res.data.averageTem)[0]) * (0.9 / (JSON.parse(res.data.averageTem)[3] - JSON.parse(res.data.averageTem)[0])) + 'rem' // 0.053=0.9rem/17 (0.9是.progress的宽度，17是进度条最高值)
            document.querySelector('.type-progress-rect').style.width = (JSON.parse(res.data.averageTem)[2] - JSON.parse(res.data.averageTem)[1]) * (0.9 / (JSON.parse(res.data.averageTem)[3] - JSON.parse(res.data.averageTem)[0])) + 'rem'
            document.querySelector('.humidity-num').style.left = humLeft - (res.data.averageHumidity.length == 2 ? 0.05 : 0.02) + 'rem'
            document.querySelector('.uv-num').style.left = uvLeft - (res.data.averageUvIndex.length == 2 ? 0.03 : 0) + 'rem'
          }
        }
        else {
          if (document.querySelector('.bigScreen-modal')) {
            document.querySelector('.bigScreen-modal .type-progress-rect').style.left = 0 + 'rem' // 0.053=0.9rem/17 (0.9是.progress的宽度，17是进度条最高值)
            document.querySelector('.bigScreen-modal .type-progress-rect').style.width = 0 + 'rem'
            document.querySelector('.bigScreen-modal .rate').style.left = 0 + 'rem'
            // 0.009=0.9rem/100 
            document.querySelector('.bigScreen-modal .rate-rays').style.left = 0 + 'rem'
            document.querySelector('.bigScreen-modal .humidity-num').style.left = 0 + 'rem'
            document.querySelector('.bigScreen-modal .uv-num').style.left = 0 + 'rem'
          }
        }
        this.updateState({
          symptomInfo: res.data,
          diseaseTotal,
          syndromeTotal
        })
        return res.data
      }
      modelMsg(res.status, res.msg)
    },
    async getOrganizationList(params) {
      const url = '/api/organization/list'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          OrgList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getWeather(params) {
      axios.get('https://devapi.qweather.com/v7/weather/now?location=120.43,30.14&key=d86d730b91f04b8ab7c2f566f86348ca').then((response) => {
        // +response.data.code == 200 ? localStorage.weatherIcon = response.data.now.icon : ''
        if (+response.data.code == 200) {
          localStorage.weatherIcon = response.data.now.icon
          localStorage.weatherText = response.data.now.text
        }
      })
      const url = '/api/warning/weatherInfo'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          weatherInfo: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getWarnList(params) {
      const url = '/api/warning/page'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          warnList: res.data.pageData,
          totalRows: res.data.totalRows
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getEventCount(params) {
      const url = `/api/warning/eventCount`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          eventCountList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getEventTop(params) {
      const url = `/api/warning/top`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          eventTopList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async eventCount(payload) {
      const params = payload
      const url = `/api/patient/eventCount`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '编辑成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getWarnDisease(params) {
      const url = `/api/warning/warnDisease`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // modelMsg(res.status, '编辑成功！')
        this.updateState({
          warnDiseaseList: JSON.parse(res.data.warnDisease),
          warnDiseaseTime: res.data.warnTimePeriod
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
  })
}