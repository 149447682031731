import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
export default {
  state: {
    name: 'patientControl',
    params: {
      page: 1,
      pageSize: 10,
    },
    totalRows: 0,
    registerList: [],
    visitList: [],
    infoList: [],
    RPData: [],
    patientInfo: {},
    prescriptionDetail: {},
    inspectDetailData: {},
    medicalSummarizeInfo: {},
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    async getPatientList(payload) {
      const params = payload
      const url = `/api/patient/page`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ registerList: res.data.pageData, totalRows: res.data.totalRows })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async editInspect(payload) {
      const params = payload
      const url = `/api/patient/editInspect`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '编辑成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async patientCollect(payload) {
      const params = payload
      const url = `/api/patient/collect`
      const res = await request.post(url, { id: params })
      if (res.status === 0) {
        modelMsg(2, '收藏成功！')
      } else {
        modelMsg(1, res.msg)
      }
    },
    async cancelCollect(payload) {
      const params = payload
      const url = `/api/patient/cancelCollect`
      const res = await request.post(url, { id: params })
      if (res.status === 0) {
        modelMsg(3, '已取消收藏！')
      } else {
        modelMsg(1, res.msg)
      }
    },
    async addInspect(payload) {
      const params = payload
      const url = `/api/patient/addInspect`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(0, '新增成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async editSummarize(payload) {
      const params = payload
      const url = `/api/patient/editSummarize`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(0, '修改成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
      return res
    },
    async addSummarize(payload) {
      const params = payload
      const url = `/api/patient/addSummarize`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(0, '编辑成功！')
      } else {
        modelMsg(res.status, res.msg)
      }
      return res
    },
    async patientDetail(payload) {
      const params = payload
      const url = `/api/patient/detail`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        res.data.csny = res.data.csny.substring(0, 4) + '-' + res.data.csny.substring(4, 6)
        this.updateState({ patientInfo: res.data })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async getRecordPage(payload) {
      const params = payload
      const url = `/api/record/page`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ visitList: res.data.pageData })

      } else {
        modelMsg(1, res.msg)
      }
    },
    async getPrescription(payload) {
      const params = payload
      const url = `/api/patient/prescriptionPage`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ infoList: res.data.pageData, totalRows: res.data.totalRows })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async getInspect(payload) {
      const params = payload
      const url = `/api/patient/inspectPage`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ infoList: res.data.pageData, totalRows: res.data.totalRows })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async getRecordDetail(payload) {
      const params = payload
      const url = `/api/record/detail`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ medicalSummarizeInfo: res.data })
      } else {
        modelMsg(1, res.msg)
      }
      return res.data.medicalSummarize
    },
    // async getRecordPage(payload) {
    //   const params = payload
    //   const url = `/api/record/page`
    //   const res = await request.get(url, { params })
    //   if (res.status === 0) {
    //     this.updateState({ infoList: res.data.pageData, totalRows: res.data.totalRows })
    //   } else {
    //     modelMsg(1, res.msg)
    //   }
    // },
    async prescriptionDetail(payload) {
      const params = payload
      const url = `/api/patient/prescriptionDetail`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ prescriptionDetail: res.data, RPData: res.data.prescriptionRps })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async inspectDetail(payload) {
      const params = payload
      const url = `/api/patient/inspectDetail`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ inspectDetailData: res.data })
      } else {
        modelMsg(1, res.msg)
      }
    },
  })
}