import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './index.styl'

class ReportDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            diabetes: '',
            activePath: '',
            tabList: [
                { name: '脉象报告', path: 'pulseReport', evalType: 8, active: false },
                { name: '舌象报告', path: 'tongueReport', evalType: 9, active: false },
                { name: '面相报告', path: 'faceReport', evalType: 10, active: false },
                { name: '处方辅助辨证报告', path: 'prescriptReport', evalType: 15, active: false },
            ]
        }
    }
    componentDidMount() {
        this.setState({ activePath: this.props.location.pathname.split('/')[1], id: this.props.history.location.search.split('id=')[1] }, () => {
            this.props.patientDetail(this.state.id)
        })
    }
    handTab = (v) => {
        if (!v.active) return
        this.props.history.push(`/${v.path + '?id='}${this.state.id}`)
        this.setState({ activePath: v.path })
    }

    render() {
        this.props.state.infoData.evaluations && this.props.state.infoData.evaluations.find(i => {
            this.state.tabList.map(v => {
                if (+i.evalType == v.evalType) {
                    v.active = true
                }
            })
        })
        return (
            <div className="reportDetail-content">
                <div className="tab-box">
                    {
                        this.state.tabList.map(v => (
                            <div
                                className={['tab-item', !v.active ? 'no-data' : this.state.activePath === v.path &&
                                    v.path === 'prescriptReport' ? 'active actives' :
                                    this.state.activePath === v.path ? 'active' : ''].join(' ')}
                                key={v.path}
                                onClick={() => this.handTab(v)}
                            >
                                {v.name}
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

const mapState = state => {
    return {
        state: state.ReportDetail
    }
}

const mapDispatch = dispatch => {
    const { ReportDetail } = dispatch
    const { updateState, patientDetail } =
        ReportDetail
    return {
        updateState: payload => updateState(payload),
        patientDetail: payload => patientDetail(payload),
    }
}

export default withRouter(
    connect(mapState, mapDispatch)(ReportDetail)
)
