import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './views/App/app'
import * as serviceWorker from './serviceWorker'

import { BrowserRouter, HashRouter } from "react-router-dom"
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
import { history } from './routers'
import { ConfigProvider } from 'antd'

import store from './store'
import zhCN from 'antd/lib/locale-provider/zh_CN';
import './assets/css/theme-ecg/theme.less'
import './assets/css/iconFont.css'
import 'antd-mobile/dist/antd-mobile.css'
import './assets/css/common.styl'

// import 'antd/dist/antd.css'
const render = Comp => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store} >
        <BrowserRouter hisrory={history}>
          <ConfigProvider locale={zhCN}>
            <Comp />
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
}

render(App)

if (module.hot) {
  module.hot.accept()
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
