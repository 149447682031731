import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'QualityControl',
    params: {
      page: 1,
      pageSize: 10
    },
    qualityList: [],
    totalRows: 1,
    qualityDetail: {},
    patientInfo: {},
    prescriptionsList: [],
    fullResult: {}
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    async getQualityList(payload = {}, rootState) {
      const url = '/api/quality/prescriptionQualityPage'
      const params = { ...rootState.QualityControl.params, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          qualityList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    },
    async getQualityDetail(payload = {}, rootState) {
      const url = `/api/quality/prescriptionQualityDetail/${payload.id}`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          qualityDetail: res.data,
          fullResult: res.data.fullResult ? JSON.parse(res.data.fullResult) : {}
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPrescriptionsList(payload = {}, rootState) {
      const url = `/api/quality/prescriptions/${payload.patientId}`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          prescriptionsList: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}
