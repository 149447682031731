import React, { Component, lazy, Suspense } from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
// import createHistory from 'history/createBrowserHistory'

import Pc, { Mobile } from './mode'
const createHistory = require("history").createBrowserHistory

const ReportDetail = lazy(() => import('./views/ReportDetail/index'))
const PulseReport = lazy(() => import('./views/ReportDetail/pulseReport'))
const TongueReport = lazy(() => import('./views/ReportDetail/tongueReport'))
const FaceReport = lazy(() => import('./views/ReportDetail/faceReport'))
const PrescriptReport = lazy(() => import('./views/ReportDetail/prescriptReport'))

const Login = lazy(() => import('./views/Logins/logins'))
const TestPage = lazy(() => import('./views/TestPage/testPage'))
const Index = lazy(() => import('./views/Index/index'))

const Permission = lazy(() => import('./views/Permission/permission'))
const RoleControll = lazy(() => import('./views/RoleControll/roleControll'))
const UserControll = lazy(() => import('./views/UserControll/userControll'))
const HospitalControll = lazy(() => import('./views/HospitalControll/hospitalControl'))

const Personal = lazy(() => import('./views/Personal/personal'))


//error-page
const ErrorPage404 = lazy(() => import('./views/ErrorPage/404'))

const DataDisplay = lazy(() => import('./views/DataDisplay/dataDisplay'))

const EcgUserList = lazy(() => import('./views/EcgUserList/ecgUserList'))
const AddEcgUser = lazy(() => import('./views/EcgUserList/addEcgUser'))

const EcgHospitalList = lazy(() => import('./views/EcgHospitalList/ecgHospitalList'))
const AddEcgHospital = lazy(() => import('./views/EcgHospitalList/addEcgHospital'))

const AddRegister = lazy(() => import('./views/RegisterList/addRegister'))
const RegisterList = lazy(() => import('./views/RegisterList/registerList'))
const RegisterDetail = lazy(() => import('./views/RegisterList/registerDetail'))

const ActiveSerial = lazy(() => import('./views/ActiveSerial/activeSerial'))

const EcgView = lazy(() => import('./views/EcgView/ecgView'))

const QualityOverview = lazy(() => import('./views/QualityOverview/qualityOverview'))
const QualityControl = lazy(() => import('./views/QualityControl/qualityControl'))
const QualityControlDetail = lazy(() => import('./views/QualityControl/qualityControlDetail/qualityControlDetail'))

const CaseDetail = lazy(() => import('./views/CaseReport/caseDetail'))
const ExpertSelectionDetail = lazy(() => import('./views/ExpertSelection/expertSelectionDetail'))
const CaseReport = lazy(() => import('./views/CaseReport/caseReport'))
const SelectionOverview = lazy(() => import('./views/SelectionOverview/selectionOverview'))
const ProjectDetail = lazy(() => import('./views/SelectionOverview/projectDetail'))
const PatientControl = lazy(() => import('./views/PatientControl/patientControl'))
const PatientDetail = lazy(() => import('./views/PatientControl/patientDetail'))
const ExpertSelection = lazy(() => import('./views/ExpertSelection/expertSelectionList'))
const WarnList = lazy(() => import('./views/Warning/warnList'))
const BigScreen = lazy(() => import('./views/Warning/bigScreen'))
const Search = lazy(() => import('./views/Search/search'))
const SearchContent = lazy(() => import('./views/Search/searchContent'))
const FreePanel = lazy(() => import('./views/Search/freePanel'))
const SearchHistory = lazy(() => import('./views/Search/searchHistory'))
const SearchCollect = lazy(() => import('./views/Search/searchCollect'))
const SearchOverview = lazy(() => import('./views/Search/searchOverview'))


export const history = createHistory()


class Routes extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path='/login' component={() => <Login />} />
            <Route path='/activeSerial/:id' component={() => <ActiveSerial />} />
            <Route path='/registerDetail/:id' component={() => <RegisterDetail />} />

            <Route path='/dataDisplay' component={() => <DataDisplay />} />
            <Route path='/ecgView/:id' component={() => <EcgView />} />
            <Route path='/h5' component={() =>
              <Mobile>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    {/* <Route path='/h5/h5login' component={()=> <H5Login />} /> */}
                  </Switch>
                </Suspense>
              </Mobile>} />
            <Route path='/bigScreen' component={() => <BigScreen />} />
            <Route path='/search' component={() => <Search />} />
            <Route path='/searchContent' component={() => <SearchContent />} />
            <Route path='/freePanel' component={() => <FreePanel />} />
            <Route path='/searchHistory' component={() => <SearchHistory />} />
            <Route path='/searchCollect' component={() => <SearchCollect />} />
            <Route path='/searchOverview' component={() => <SearchOverview />} />
            <Route path='/' render={() =>
              localStorage.token ? <Pc>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route path='/' exact render={() => <Redirect to='/qualityOverview' />} />
                    <Route path='/qualityControl' component={() => <QualityControl />} />
                    <Route path='/expertSelection' component={() => <ExpertSelection />} />
                    <Route path='/caseReport' component={() => <CaseReport />} />
                    <Route path='/qualityControlDetail/:id' component={() => <QualityControlDetail />} />
                    <Route path='/caseDetail/:id' component={() => <CaseDetail />} />
                    <Route path='/expertReviewDetail/:id/:caseId/:isReview' component={() => <ExpertSelectionDetail />} />
                    <Route path='/pulseReport' component={() => <PulseReport />} />
                    <Route path='/tongueReport' component={() => <TongueReport />} />
                    <Route path='/faceReport' component={() => <FaceReport />} />
                    <Route path='/prescriptReport' component={() => <PrescriptReport />} />

                    <Route path='/permission' component={() => <Permission />} />
                    <Route path='/roleControll' component={() => <RoleControll />} />
                    <Route path='/userControll' component={() => <UserControll />} />
                    <Route path='/hospitalControll' component={() => <HospitalControll />} />

                    <Route path='/testPage' component={() => <TestPage />} />
                    <Route path='/personal/:id' component={() => <Personal />} />

                    <Route path='/ecgUserList' component={() => <EcgUserList />} />
                    <Route path='/addEcgUser/:id?' component={() => <AddEcgUser />} />

                    <Route path='/ecgHospitalList' component={() => <EcgHospitalList />} />
                    <Route path='/addEcgHospital/:id?' component={() => <AddEcgHospital />} />


                    <Route path='/addRegister' component={() => <AddRegister />} />
                    <Route path='/registerList' component={() => <RegisterList />} />
                    <Route path='/selectionOverview' component={() => <SelectionOverview />} />
                    <Route path='/qualityOverview' component={() => <QualityOverview />} />
                    <Route path='/projectDetail/:id' component={() => <ProjectDetail />} />
                    <Route path='/patientControl' component={() => <PatientControl />} />
                    <Route path='/patientDetail/:id' component={() => <PatientDetail />} />
                    <Route path='/warnList' component={() => <WarnList />} />



                    {/* <Route path="/404" component={()=> <ErrorPage404 />} />
                    <Redirect from='*' to='/404' /> */}
                  </Switch>
                </Suspense>
              </Pc> : <Redirect to='/login' />} />
          </Switch>

        </Suspense>
      </ConnectedRouter>
    )
  }
}

export default Routes;
