import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
//地区数据整理
const interprit = (arr, options, dirty) => {
  let isLeaf
  dirty ? isLeaf = true : isLeaf = false
    arr.forEach((v) => {
      options.push({
        value: v.id,
        label: v.name,
        type: v.type,
        parentId: v.parentId,
        isLeaf
      })
    })
}
export default {
  state: {
    name: 'UserControll',
    roleList: [],
    allRoleList: [],
    pageSize: 12,
    userList: [],
    loading: true,
    page: 1,
    visible: false,
    confirmLoading: false,
    totalRows: 0,
    phone: '',
    realName: '',
    nation: [],//国家
    tit: [],//职称
    userStatus: -1,
    areaList: [],//地区
    dePart: [],
    userListParams: '',
    status: '',//当前编辑用户状态
    authentication: ''
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    showModal(state) {
      return { ...state, ...{ visible: true, confirmLoading: false } }
    },
    closeModal(state) {
      return { ...state, ...{ visible: false, confirmLoading: false } }
    },
    showConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: true }
      }
    },
    closeConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: false }
      }
    }
  },
  effects: () => ({
    // 获取国籍和职称
    async getByDictKey(payload) {
      const url = '/hospital/api/sys/dict/getByDictGroup'
      const params = payload
      const res = await request.get(url, { params })
      if(res.status === 0) {
        if(params.dictGroup === 'title') {
          this.updateState({
            tit: res.data
          })
        } else {
          this.updateState({
            nation: res.data
          })
        }
      }
    },
    async bindRoles(payload) {
      const url = '/auth/api/sys/user/authorize'
      const params = payload
      const res = await request.post(url, params)
      modelMsg(res.status, res.msg)
    },
    async getRoleList() {
      const url = '/auth/api/sys/role/page'
      const params = {
        page: 1,
        pageSize: 30
      }
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          roleList: res.data.pageData
        })
      }
    },
    // 获取地区
    async getArea(payload, rootState) {
      const that = this
      const areaList = rootState.UserControll.areaList
      const url = '/api/area/getAreas'
      const dirty = payload.dirty || ''
      delete payload.dirty
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        if(payload.type === '1') {
          let options = []
          interprit(res.data, options)
          this.updateState({
            loading: false,
            areaList: options
          })
        }
        if(payload.type === '2') {
          let children = []
          interprit(res.data, children)
          areaList.forEach((v, i) => {
            if(v.value === children[0].parentId) {
               const obj = Object.assign({}, v, { children })
               let list = areaList
               list.splice(i, 1, obj)
               that.updateState({
                loading: false,
                areaList: list
              })
            }
          })
        }
        if(payload.type === '3') {
          let children = []
          interprit(res.data, children, dirty)
          areaList.forEach((v, i) => {
            if(v.children) {
              v.children.forEach((v, j) => {
                if(v.value === children[0].parentId) {
                  let list = areaList
                  let list1 = areaList[i]
                  let list2 = list1.children[j]
                  const obj1 = Object.assign({}, list2, { children })
                  list1.children.splice(j, 1, obj1)
                  list.splice(i, 1, list1)
                  that.updateState({
                    loading: false,
                    areaList: list
                  })
                }
              })
            }
          })
        }
      }
    },
    // 获取医院
    async getHospitals(payload, rootState) {
      const that = this
      const areaList = rootState.UserControll.areaList
      console.log(payload)
      const url = `/api/organization/getOrganizationsForArea/${payload.areaId.split(',')[2] }`
      // const params = payload
      const res = await request.get(url)
      if (res.status === 0) {
        let children = []
        if(res.data.length === 0 ) {
          modelMsg(1, '无数据')
          return
        }
        res.data.forEach((v) => {
          children.push({
            value: v.id,
            label: v.organizationName,
            type: v.organizationType,
            id: v.id,
            parentId: v.areaId,
            isLeaf: true
          })
        })
        areaList.forEach((v, i) => {
          if(v.children) {
            v.children.forEach((w, j) => {
              if(w.children) {
                w.children.forEach((z, k) => {
                  if(z.value === children[0].parentId) {
                    let list = areaList
                    let list1 = areaList[i]
                    let list2 = list1.children[j]
                    let list3 = list2.children[k]
                    const obj1 = Object.assign({}, list3, { children })
                    list2.children.splice(k, 1, obj1)
                    list1.children.splice(j, 1, list2)
                    list.splice(i, 1, list1)
                    that.updateState({
                      loading: false,
                      areaList: list
                    })
                  }
                })
              }
            })
          }
        })
      }
    },
    // 获取科室
    async getDepart(payload) {
      const url = '/hospital/api/sys/hospital/departments'
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          dePart: res.data
        })
      }
    },
    async getUserList(payload, rootState) {
      const url = '/auth/api/sys/user/list'
      let params
      if(rootState.UserControll.userListParams) {
        params = { ...rootState.UserControll.userListParams, ...payload }
      } else {
        params = {
          page: payload.page,
          pageSize: rootState.UserControll.pageSize,
          phone: payload.phone || rootState.UserControll.phone,
          realName: payload.realName || rootState.UserControll.realName,
          userStatus: payload.userStatus || rootState.UserControll.userStatus
        }
      }
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          userList: res.data.pageData,
          totalRows: res.data.totalRows,
          userListParams: params,
          page: params.page
        })
      }
    },
    async editUser(payload, rootState) {
      let params
      !payload.relatedHospitalCode || payload.relatedHospitalCode === [] ? delete payload.relatedHospitalCode : delete ''
      payload.remark.replace(/\s/g,'') === '' ? delete payload.remark :  delete ''
      if(payload.userStatus === 0 || payload.userStatus === 1 ) {
        params = payload
      } else {
        params = { ...payload, ...{ userStatus: rootState.UserControll.status }}
      }
      if(payload.authentication === true || payload.authentication === false ) {
        params = payload
      } else {
        params = { ...payload, ...{ authentication: rootState.UserControll.authentication }}
      }
      const url = `/auth/api/sys/user/modify/${params.userId}`
      const res = await request.post(url, params )
      if (res.status === 0) {
        const { page } = rootState.UserControll
        modelMsg(res.status, '修改成功')
        this.getUserList({ page })
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async addUser(payload, rootState) {
      !payload.relatedHospitalCode || payload.relatedHospitalCode === [] ? delete payload.relatedHospitalCode : delete ''
      payload.remark.replace(/\s/g,'') === '' ? delete payload.remark :  delete ''
      const params = { ...payload, ...{ userStatus: 0 } }
      const url = '/auth/api/sys/user/add'
      const res = await request.post(url, params )
      if (res.status === 0) {
        const { page } = rootState.UserControll
        modelMsg(res.status, '添加成功')
        this.getUserList({ page })
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    }
  })

}