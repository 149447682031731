import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import { push } from 'react-router-redux'
const list = ['V1', 'V2', 'V3R', 'V4R', 'V5R', 'V6R']
const cols = 4
const rows = 3
const dataRange = 250 / cols
const frontPadding = 5
const dataGap = 25
const ecgtype = 3
const calcHeightCenter = 135
var getYOffset = row => {
  return (
    parseInt(
      (dataGap / 2) * (rows - (ecgtype === 0 ? 0 : 0.5)) - dataGap * row
    ) + calcHeightCenter
  )
}

var swapArr = function (arr, i, j) {
  let name = arr[j].name

  let temp = arr[i]

  arr[i] = arr[j]

  arr[j] = temp

  arr[i].name = arr[j].name

  arr[j].name = name
}

var exchangeArr = function (arr, j, i) {
  let data = JSON.parse(localStorage.getItem('ecg'))
  let temp = JSON.parse(JSON.stringify(data[j]))
  // console.log(temp)
  // let col = Math.floor(i / rows)
  // let row = i % rows
  // let xOffset = frontPadding
  // let yOffset = getYOffset(row)
  // let d = Array.from(temp.coordinates, v => {
  //   return [v[0] + xOffset, v[1] + yOffset]
  // }).filter(
  //   v =>
  //     v[0] >= frontPadding + dataRange * col &&
  //     v[0] <= frontPadding + dataRange + dataRange * col
  // )
  let name = arr[i].name
  arr[i] = temp
  arr[i].name = name
}
export default {
  state: {
    name: 'RegisterList',
    activeSelect: '未填写',
    faceReport: {},
    tongueReport: {},
    params: {
      page: 1,
      pageSize: 10
    },
    noParams: {
      page: 1,
      pageSize: 10,
      diabetes: '',
      hypertension: '',
      fillTimeStart: '',
      fillTimeEnd: '',
      keyword: ''
    },
    registerList: [],
    notCompletedList: [],
    totalRows: 1,
    noTotalRows: 1,
    registerDetailInfo: {},
    visible: false,
    sourceNameList: []
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    async getRegisterList(payload = {}, rootState) {
      const url = '/api/patient/page'
      let params = { ...rootState.RegisterList.params, ...payload }
      if (params.gender === -1) {
        params.gender = ''
      }
      const res = await request.get(url, { params })
      if (res.status === 0) {
        if (params.gender === '') {
          params = { ...params, gender: -1 }
        }
        console.log(params)
        this.updateState({
          registerList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    },

    async getNotCompletedList(payload = {}, rootState) {
      const url = '/api/study/filledPage'
      const params = { ...rootState.RegisterList.noParams, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          notCompletedList: res.data.pageData,
          noTotalRows: res.data.totalRows,
          noParams: params
        })
      }
    },
    async uploadImg(payload, rootState) {
      const url = '/api/image/upload'
      // const params = { ...rootState.RegisterList.noParams, ...payload }
      const res = await request.post(url, payload)

      if (res.status === 0) {
        this.updateState({
          // notCompletedList: res.data.pageData,
          // noTotalRows: res.data.totalRows,
          // noParams: params
        })
      }
    },
    async getDoctorList(payload = {}, rootState) {
      const url = '/api/patient/getSourceNameList'
      // const params = { ...rootState.RegisterList.noParams, ...payload }
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          sourceNameList: res.data
          // noTotalRows: res.data.totalRows,
          // noParams: params
        })
      }
    },

    async remove(payload) {
      const url = '/api/study/remove'
      const params = payload

      const res = await request.post(url, { studyId: params })
      if (res.status === 0) {
        // setTimeout(() => {
        //   dispatch(push('/registerList'))
        // })
        modelMsg(res.status, '刪除成功')
        this.getRegisterList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addRegister(payload) {
      const url = '/api/study/createStudy'
      const params = payload

      const res = await request.post(url, { serialNos: params })
      if (res.status === 0) {
        setTimeout(() => {
          dispatch(push('/registerList'))
        })
        modelMsg(res.status, '图像新增成功')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getRegisterDetail(payload) {
      const url = `/api/patient/detail/${payload.id}`
      const params = {
        studyId: payload.id
      }
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.showModal(true)
        this.updateState({
          registerDetailInfo: res.data,
          faceReport: res.data.evaluations[0],
          tongueReport: res.data.evaluations[2]
        })
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.data
    },
    showModal(payload) {
      this.updateState({
        visible: payload
      })
    },
    async submit(payload, rootState) {
      const url = '/api/study/save'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '图片标注成功')
        // window.location.href = `/registerDetail/${payload.studyId}`
        this.getRegisterDetail({ id: window.location.pathname.split('/')[2] })
      } else {
        modelMsg(res.status, res.msg)
      }
    },

    async submitNext(payload) {
      const url = '/api/study/saveAndNext'
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        if (res.data) {
          modelMsg(res.status, '图片标注成功”，页面跳转到下一条图片页面')
          const id = res.data.id || res.data.studyId
          window.location.href = `/registerDetail/${id}`
        } else {
          modelMsg(res.status, '标注均已完成！')
          this.getRegisterDetail({
            id: payload.studyId
          })
        }
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}
