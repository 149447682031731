import request from '../../request'
export default {
  state: {
    name: 'Layouts',
    siderDefaultKey: 'qqq',
    indexPosition: '',
    breadPosition: '',
    seBreadPosition: '',
    info: ''
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    setIndexPosition(state, indexName) {
      return { ...state, ...{ indexPosition: indexName } }
    },
    setBreadPosition(state, positionName) {
      
      return { ...state, ...{ breadPosition: positionName } }
    },
    setSeBreadPosition(state, positionName) {
      return { ...state, ...{ seBreadPosition: positionName } }
    }
  },
  effects: {
    async logout() {
      const url = '/api/user/logout'
      const res = await request.get(url)
      localStorage.clear('token')

    },
    async getInfo() {
      const url = `${localStorage.root}/account/getAccountInfo`
      const res = await request.get(url, {})

      if (res.status === 0) {
        const info = res.data
        this.updateState({ info })
      }
    },
    async getPermsKeys(payload, rootState) {
      const url = '/auth/getPermsKeys'

      const res = await request.get(url)

      if (res.status === 0) {
        localStorage.permsKeys = res.data

        this.updateState({
          permsKey: res.data
        })
      }
    }
   }
}