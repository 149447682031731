import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

let recordDetailList = []

export default {
  state: {
    name: 'CaseReport',
    params: {
      page: 1,
      pageSize: 10
    },
    caseReportList: [],
    totalRows: 1,
    caseDetail: {},
    patientList: [],
    recordList: [],
    patientDetail: {},
    recordDetail: [],
    recordDetailList: [],
    prescriptionList: [],
    prescriptionDetail: {},
    inspectList: [],
    inspectDetail: {},
    scoreList: [],
    caseLogList: [],
    scoreDetail: {},
    reviewExpertList: []
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    async getCaseReportList(payload = {}, rootState) {
      const url = '/api/project/selectDoctorProjectPage'
      const params = {
        ...rootState.CaseReport.params,
        ...payload
      }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          caseReportList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    },
    async getCaseDetail(payload = {}, rootState) {
      const url = `/api/case/detail`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          caseDetail: res.data
        })
        this.getPatientDetail({ id: res.data.patientId })
        const relatedMedicalRecord = res.data.relatedMedicalRecord.split(',')
        console.log(relatedMedicalRecord)
        recordDetailList = []
        relatedMedicalRecord.forEach(item => {
          this.getRecordDetailList({ id: item })
        })
        this.getCaseLog({ caseId: payload.id })
        this.getScorePageList({
          status: 1,
          projectId: payload.id
          // projectId: res.data.projectId
          // orgId: res.data.orgId
        })
        // this.getRecordList({ id: res.data.patientId })
        // this.getProJectDetail({ id: res.data.id })
        // this.getScoreDetail({ id: res.data.patientId })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPatientList(payload = {}, rootState) {
      const url = `/api/patient/page`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          patientList: res.data.pageData
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPatientDetail(payload = {}, rootState) {
      const url = `/api/patient/detail`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          patientDetail: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getRecordDetailList(payload = {}, rootState) {
      // console.log(rootState.CaseReport)
      const url = `/api/record/detail`
      const params = payload
      const res = await request.get(url, { params })
      console.log(res.data)
      if (res.status === 0) {
        recordDetailList.push(res.data)
        console.log(recordDetailList)
        this.updateState({
          recordDetailList: [...recordDetailList]
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getRecordDetail(payload = {}, rootState) {
      const url = `/api/record/detail`
      const params = payload
      const res = await request.get(url, { params })
      console.log(res.data)
      if (res.status === 0) {
        this.updateState({
          recordDetail: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getRecordList(payload = {}, rootState) {
      const url = `/api/record/page`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          recordList: res.data.pageData
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addCase(payload = {}, rootState) {
      const url = `/api/case/add`
      const res = await request.post(url, payload)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        this.getCaseReportList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPrescriptionPageList(payload = {}, rootState) {
      const url = `/api/patient/prescriptionPage`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          prescriptionList: res.data.pageData
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getInspectPageList(payload = {}, rootState) {
      const url = `/api/patient/inspectPage`
      console.log(payload)
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          inspectList: res.data.pageData
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getInspectDetail(payload = {}, rootState) {
      const url = `/api/patient/inspectDetail`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          inspectDetail: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPrescriptionDetail(payload = {}, rootState) {
      const url = `/api/patient/prescriptionDetail`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          prescriptionDetail: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getScorePageList(payload = {}, rootState) {
      const url = `/api/score/page`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          scoreList: res.data.pageData
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getCaseLog(payload = {}, rootState) {
      const url = `/api/case/getCaseLog`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          caseLogList: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getDetailByProjectId(payload = {}, rootState) {
      const url = `/api/case/getDetailByProjectId`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          caseDetail: res.data
        })
        recordDetailList = []
        this.getPatientDetail({ id: res.data.patientId })
        if (res.data.relatedMedicalRecord.length > 1) {
          const relatedMedicalRecord = res.data.relatedMedicalRecord.split(',')
          relatedMedicalRecord.forEach(item => {
            this.getRecordDetailList({ id: item })
          })
        } else {
          this.getRecordDetailList({ id: res.data.relatedMedicalRecord })
        }
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getReviewExpertList(payload) {
      const url = `/api/case/reviewExpertList`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        const reviewExpertList = res.data.map(item => {
          return {
            title: item.realName,
            key: item.id,
            disabled: item.checked
          }
        })
        const selectedList = res.data
          .filter(f => f.checked)
          .map(item => item.id)
        this.updateState({ reviewExpertList, selectedList })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async reviewAssign(payload) {
      const url = `/api/score/review`
      const caseId = payload.caseId
      const params = payload
      delete params.caseId
      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getCaseDetail({ id: caseId })
        this.getScoreDetail({ id: payload.scoreId })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async scoreAssign(payload) {
      const params = payload
      const url = `/api/score/assign`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '指派成功')
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async getScoreDetail(payload) {
      const url = '/api/score/detail'
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // this.getCaseDetail({ id: payload.scoreId })
        this.updateState({
          scoreDetail: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}
