import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'RoleControll',
    roleParams: {
      page: 1,
      pageSize: 14
    },
    userParams: {
      page: 1,
      pageSize: 8
    },
    totalRows: 0,
    userTotalRows: 0,
    roleList: [],
    visible: false,
    page: 1,
    confirmLoading: false,
    drawVisible: false,
    treeInfo: [],
    checkedKeys: [],
    addName: '',
    addId: '',
    userList: []
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    showModal(state) {
      return { ...state, ...{ visible: true, confirmLoading: false } }
    },
    closeModal(state) {
      return { ...state, ...{ visible: false, confirmLoading: false } }
    },
    showConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: true }
      }
    },
    closeConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: false }
      }
    }
  },
  effects: () => ({
    async getRolePerms(addId) {
      const url = '/auth/api/sys/role/getRolePerms'
      const params = {
        roleId: addId
      }
      const res = await request.post(url, params)

      if (res.status === 0) {
        this.updateState({
          checkedKeys: res.data
        })
      }
    },
    async setRolePerms(payload, rootState) {
      const ids = rootState.RoleControll.checkedKeys.checked.join(',')

      const url = '/auth/api/sys/role/allocatePerms'
      const params = {
        roleId: rootState.RoleControll.addId,
        permsIds: ids,
        remark: ''
      }
      const res = await request.post(url, params)
      if (res.status === 0) {
        this.updateState({
          drawVisible: false
        })
      }
      modelMsg(res.status, res.msg)

    },
    async getTreeInfo() {
      const url = '/auth/api/sys/perms/getTreeInfo'
      const params = {
        parentPermsId: 0
      }

      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          treeInfo: res.data
        })
      }
    },
    async getRole(payload, rootState) {
      const url = '/auth/api/sys/role/page'
      const params = { ...rootState.RoleControll.roleParams, ...payload }

      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          roleList: res.data.pageData,
          totalRows: res.data.totalRows,
          roleParams: params
        })
      }
    },
    async getUserList(payload, rootState) {
      const url = '/auth/api/sys/role/getRoleUsers'
      const params = { ...rootState.RoleControll.userParams, ...payload }

      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          userList: res.data.pageData,
          userTotalRows: res.data.totalRows,
          userParams: params
        })
      }
    },
    async addRole(payload) {
      if (!payload.roleName) {
        modelMsg(1, '请先输入角色名')
        this.closeConfirmLoading()
        return false
      }
      const url = '/auth/api/sys/role/add'
      const params = payload

      const res = await request.post(url, params)
      if(res.status === 0) {
        modelMsg(res.status, '添加成功')
        this.getRole()
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async editRole(payload) {
      const url = '/auth/api/sys/role/modify'
      const params = payload

      const res = await request.post(url, params)
      if(res.status === 0) {
        modelMsg(res.status, '修改成功')
        this.getRole()
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async roleDelete(payload) {
      const url = '/auth/api/sys/role/delete'
      const params = payload

      const res = await request.delete(url, { params })
      if(res.status === 0) {
        modelMsg(res.status, '删除成功')
        this.getRole()
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}