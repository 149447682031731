import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state : {
    name: 'HospitalControll',
    pageSize: 12,
    hospitalList: [],
    hospitalListParams: {pageSize: 12},
    totalRows: 0,
    loading: true,
    hospitals: [],
    configData: [],
    configInfos: [

    ],
    page: 1,
    visible: false,
    configVisible: false,

    confirmLoading: false,
    disTypeOption: [],
    symTypeOption: [],
    setList: [
      { name: '套餐0', value: '0' },
      { name: '套餐1', value: '1' },
      { name: '套餐2', value: '2' },
      // { name: '套餐3', value: '3' },
    ]
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    showModal(state) {
      return { ...state, ...{ visible: true, confirmLoading: false } }
    },
    operaShowModal(state) {
      return { ...state, ...{ configVisible: true, confirmLoading: false } }
    },
    closeModal(state) {
      return { ...state, ...{ visible: false, confirmLoading: false } }
    },
    operaCloseModal(state) {
      return { ...state, ...{ configVisible: false, confirmLoading: false } }
    },
    showConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: true }
      }
    },
    closeConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: false }
      }
    }
  },
  effects: (dispatch) => ({
    async edit(payload, rootState) {
      let url
      if(payload.title === '编辑医院') {
        url = `/hospital/api/sys/hospital/modifyHospital/${payload.id}`
      } else {
        url = `/hospital/api/sys/hospital/modifyDepartment/${payload.id}`
      }

      if (payload.aiType) {
        if (typeof payload.aiType === 'string') {

        } else {
          if( payload.aiType.length === 0) {
            modelMsg(1, '请选择ai类型')
            this.closeConfirmLoading()
            return false
          }
          payload.aiType = payload.aiType.join(',')
        }
      }
      
      
      delete payload.title
      delete payload.id
      payload.remark ? delete '' : delete payload.remark
      payload.alias ? delete '' : delete payload.alias
      const params = payload
      const res = await request.post(url, params)
      if(res.status === 0) {
        modelMsg(res.status, '修改成功')
        this.getHospitalList({page: rootState.HospitalControll.page})
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async add(payload, rootState) {
      let url
      if(payload.title === '添加医院') {
        url = '/hospital/api/sys/hospital/addHospital'
      } else {
        url = '/hospital/api/sys/hospital/addDepartment'
      }
 
      delete payload.title

      if (payload.aiType) {
        if (typeof payload.aiType === 'string') {

        } else {
          if( payload.aiType.length === 0) {
            modelMsg(1, '请选择ai类型')
            return false
          }
          payload.aiType = payload.aiType.join(',')
        }
      }
      
      payload.remark ? delete '' : delete payload.remark
      payload.alias ? delete '' : delete payload.alias
      const params = payload
      const res = await request.post(url, params)
      if(res.status === 0) {
        modelMsg(res.status, '添加成功')
        this.getHospitalList({page: rootState.HospitalControll.page})
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async delHospital(payload, rootState) {
      const url = `/hospital/api/sys/hospital/delete/${payload.id}`
      const res = await request.delete(url)
      if(res.status === 0) {
        modelMsg(res.status,'删除成功')
        this.getHospitalList({ page: rootState.HospitalControll.page })
      }
    },
    async getHospitalList(payload, rootState) {
      // this.getSetList()
      this.getAllDiseaseType()
      this.getAllSymptomType()
      let params
      payload.hospitalCode ? delete '' : delete payload.hospitalCode
      payload.areaId && payload.areaId.length > 0 ? payload.areaId = `${payload.areaId[0]},${payload.areaId[1]},${payload.areaId[2]}` : delete payload.areaId
      params = { ...rootState.HospitalControll.hospitalListParams, ...payload } 
      const url = '/hospital/api/sys/hospital/list'
      const res = await request.get(url, { params })
      if(res.status === 0) {
        this.updateState({
          hospitalList: res.data.pageData,
          totalRows: res.data.totalRows,
          hospitalListParams: params,
          page: params.page
        })
      }
    },
    async getArea(payload, rootState) {
      dispatch.UserControll.getArea(payload)
    },
    async getHospitals(payload, rootState) {
      const url = '/hospital/api/sys/hospital/hospitals'
      const params = payload
      const res = await request.get(url, { params })
      if(res.status === 0) {
        this.updateState({
          hospitals: res.data
        })
      }
    },
    async getAllDiseaseType() {
      const url = `/reading/api/diseaseType/getAllDiseaseTypes`
    
      const res = await request.get(url)

      let op = []
      if (res.status === 0) {
        const list = res.data
        for (let i = 0; i < list.length; i++) {
          op.push({
            label: list[i].diseaseTypeName, 
            value: list[i].id
          })
        }
        this.updateState({
          disTypeOption: op
        })
      }
    },

    async getAllSymptomType() {
      this.getConfigMap()
      const url = `/reading/api/symptomType/getAllSymptomTypes`
    
      const res = await request.get(url)

      let op = []
      if (res.status === 0) {
        const list = res.data
        for (let i = 0; i < list.length; i++) {
          op.push({
            label: list[i].symptomTypeName, 
            value: list[i].id
          })
        }
        this.updateState({
          symTypeOption: op
        })
      }
    },
    async getConfigMap() {
      const url = '/hospital/api/sys/hospitalConfig/getAvailableConfigs'
      const res = await request.get(url)

      if (res.status === 0) {

        let newInfo = []
        const configInfos = res.data.configInfos
        console.log(configInfos)
        for (let i = 0; i < configInfos.length; i++) {
          configInfos[i].configData = configInfos[i].configData.split(',')
        }



        for (let j = 0; j < configInfos.length; j++ ) {
          let configDataArray = []
          for (let k = 0; k < configInfos[j].configData.length; k++) {
            const a = configInfos[j].configData[k].split(':')
            const v = { key: a[0], value: a[1], label: a[1]}
            configDataArray.push(v)
          }
          newInfo.push( { configType: configInfos[j].configType, configDataArray })
        }
        console.log(newInfo)
        this.updateState({
          configInfos: newInfo
        })
      //  const  map = res.data.availableConfigsMap.values()

      }
    },
    // async getSetList() {
    //   const url = '/hospital/api/sys/hospitalConfig/getUserComboType'
    //   const res = await request.get(url)

    //   if (res.status === 0) {
    //     const data = res.data
    //     const setList = []
    //     for (let i in data) {
    //       setList.push({name: i, value: data[i]})
    //     }
    //     this.updateState({
    //       setList
    //     })
    //   }
    // },
    async getSetConf(payload) {
      const url = `/hospital/api/sys/hospitalConfig/${payload.hospitalCode}`
      const res = await request.get(url)
      if (res.status === 0) {
        console.log(res.data)
        this.updateState({
          configData: res.data
        })
      } else {
        // modelMsg(res.status, res.msg)
      } 
    },


    async editList(payload, rootState) {
      const url = `/hospital/api/sys/hospitalConfig/${payload.hospitalCode}`
      console.log(payload)
      if (payload.configDatas.length === 0) {
        modelMsg(1, '请先选择套餐')
        this.closeConfirmLoading()
        return false
      }

      const params = {
        data:  JSON.stringify( {configInfos: payload.configDatas} )
      }

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '修改成功')
        this.getSetConf(payload)
        this.operaCloseModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },

  })
}