import request from '../../request'
import axios from 'axios'
import { baseUrl } from '../../request'
import { push } from 'react-router-redux'
import { modelMsg } from '../../components/modelMethods'
import { message } from 'antd';
const md5 = require('js-md5')

export default {
  state: {
    name: 'qualityOverview',
    params: {
      page: 1,
      pageSize: 10
    },
    totalRows: 0,
    registerList: [],
    OrgList: [],
    doctorList: [],
    departmentList: [],
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    closeAlert(state) {
      return { ...state, ...{ status: -1 } }
    }
  },
  effects: (dispatch) => ({
    async getQualityInfo(params) {
      const url = '/api/quality/prescriptionQualityOverview'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // this.updateState({
        //   qualityInfo: res.data
        // })
        return res
      }
      modelMsg(res.status, res.msg)
    },
    async setUserAuth(params) {
      const url = `/api/user/auth?account=${params}`
      const res = await request.post(url)
      if (res.status === 0) {
        localStorage.token = res.data.token
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${res.data.token}`
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getQualityList(params) {
      const url = '/api/quality/prescriptionQualityStatInfo'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          registerList: res.data.pageData,
          totalRows: res.data.totalRows
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getOrganizationList(params) {
      const url = '/api/organization/list'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          OrgList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getDepartmentList(params) {
      const url = '/api/quality/prescriptions/departmentList'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          departmentList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getDoctorList(params) {
      const url = '/api/quality/prescriptions/doctorList'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          doctorList: res.data,
        })
        return
      }
      modelMsg(res.status, res.msg)
    },
  })
}