import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'Permission',
    count: 0,
    treeInfo: [],
    permsList: [],
    confirmLoading: false,
    modalVisible: false,
    parentPermsId: 0,
    totalRows: 0,
    pageSize: 12,
    addParentName: 'Root',
    addParentType: 0,
    page: 1
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    showModal(state) {
      return {
        ...state,
        ...{ modalVisible: true, confirmLoading: false }
      }
    },
    showConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: true }
      }
    },
    closeModal(state) {
      return {
        ...state,
        ...{ modalVisible: false, confirmLoading: false }
      }
    },
    closeConfirmLoading(state) {
      return {
        ...state,
        ...{ confirmLoading: false }
      }
    }
  },
  effects: () => ({
    async getTreeInfo() {
      const url = '/auth/api/sys/perms/getTreeInfo'
      const params = {
        parentPermsId: 0
      }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          treeInfo: res.data
        })
      }
    },
    async deletePerms(payload, rootState) {
      const url = '/auth/api/sys/perms/delPerms'
      const params = payload

      const res = await request.delete(url, { params })

      if (res.status === 0) {
        this.getPermsList({parentPermsId: rootState.Permission.parentPermsId})
    
        if (payload.isModule) {
          // 侧边栏 的模块删除特有的 操作
          this.updateState({
            addParentName: ''
          })
        }
      }

      modelMsg(res.status, res.msg)
    },
    async addPerms(payload) {
      let url = ''
 
      switch (payload.type) {
        case 0:
          url = '/auth/api/sys/perms/addModule'
          break;
        case 1:
          url = '/auth/api/sys/perms/addFunction'
          break;
        case 2:
          url = '/auth/api/sys/perms/addInterface'
          break;
        default:
          url = '/auth/api/sys/perms/addModule'
          break;
      }
      const params = payload
      const res = await request.post(url, params)

      if (res.status === 0) {
        modelMsg(res.status, '添加成功')
        this.getPermsList(payload)
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async editPerms(payload, rootState) {
      let url = ''

      switch (payload.type) {
        case 0:
          url = '/auth/api/sys/perms/modifyModule'
          break;
        case 1:
          url = '/auth/api/sys/perms/modifyFunction'
          break;
        case 2:
          url = '/auth/api/sys/perms/modifyInterface'
          break;
        default:
          url = '/auth/api/sys/perms/modifyModule'
          break;
      }
      const params = payload
      const res = await request.post(url, params)

      if (res.status === 0) {
        modelMsg(res.status, '修改成功')
        this.getPermsList({parentPermsId: rootState.Permission.parentPermsId})
        this.closeModal()
      } else {
        this.closeConfirmLoading()
        modelMsg(res.status, res.msg)
      }
    },
    async getPermsList(payload, rootState) {
      const { parentPermsId } = payload

      const url = '/auth/api/sys/perms/list'
      const params = {
        page: payload.page ? payload.page : rootState.Permission.page,
        pageSize: rootState.Permission.pageSize,
        parentPermsId
      }

      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          permsList: res.data.pageData,
          page: payload.page ? payload.page : 1,
          parentPermsId: parentPermsId,
          totalRows: res.data.totalRows,
          addParentId:  payload.parentPermsId === 0 ? 0 : rootState.Permission.addParentId,
          addParentName: payload.parentPermsId === 0 ? 'Root' : rootState.Permission.addParentName
        })
  
        this.getTreeInfo()
      }
    }
  })
}